/* Main App */
.header {
  background: #fff;
  /* padding-top: 12px; */
  padding-left: 20px;
}

.logo {
  width: 40px;
  height: 40px;
}

.subheader {
  padding-top: 12px;
  background: #000;
  padding-left: 10px;
}

.subHeaderMenuItem {
  color: #fff;
}

.content {
  overflow: initial;
  margin: 24px 16px;
  padding: 20px;
  height: 100vh;
}

.profileMenuBar {
  margin-top: -44px;
  background: #545a6d;
  color: #fff;
  padding: 5px;
  margin-left: -36px;
  margin-right: -36px;
  height: 38px;
  width: auto;
}

/* BREADCRUMB */
.ant-breadcrumb {
  padding-left: 35px;
  padding-top: 5px;
  font-size: 18px;
}
.ant-breadcrumb .ant-breadcrumb-link {
  color: #fff;
}

.ant-breadcrumb .ant-breadcrumb-link:hover {
  color: #fff;
}

.ant-breadcrumb .ant-breadcrumb-separator {
  color: #fff;
}

.myProfileSubHeader {
  color: #fff;
  cursor: auto;
  padding-left: 35px;
  font-size: 18px;
}

.myProfileSubHeader:hover {
  color: #fff !important;
  cursor: auto !important;
  background-color: #545a6d !important;
}

.myProfileMenuItems {
  color: #fff;
  padding-right: 30px;
  font-size: 14px;
}

.membershipLevelsTitle {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  color: #26225a;
}

.addressProfileTitle {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  color: #26225a;
}

/* Sider */
.sider {
  height: 100vh;
  left: 0;
  bottom: 0;
  top: 0;
}

/* FOOTER */
.footer {
  padding-bottom: 12px;
  text-align: center;
}

.menu-bar {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 250px;
}

.ant-menu-item-group-title {
  background-color: #bcbec0;
  color: #26225a !important;
}

.ant-menu-item-selected {
  background-color: #66618d !important;
  color: #fff !important;
}

/* USER LIST */
.user-table, .course-table {
  width: 100%;
}

.userListSearchBox, .courseListSearchBox {
  margin-bottom: 8px;
}

.modal-table .ant-table-thead .ant-table-cell {
  background-color: white !important;
  color: black !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #66618d !important;
  color: #fff !important;
}

.tableRowActiveUser, .tableRowActiveCourse {
  background-color: #ffffff;
}

.tableRowInactiveUser, .tableRowInactiveCourse {
  background-color: #bbbec0;
}

.accountLevelIcons {
  padding: 5px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

/* PROFILE MENU ICONS */
.icon {
  margin-right: 2px;
}

/* CONFIRM BUTTONS */
.confirmButton {
  color: #26225a;
  border-color: #262259;
}

.confirmButton:hover:enabled {
  background-color: #26225a !important;
  color: white !important;
  border-color: white !important;
}

.confirmUserActionsButton {
  color: #fff;
  border-color: #262259;
  background-color: #262259;
  margin-bottom: 8px;
}

.confirmUserActionsButton:hover {
  color: #fff !important;
  border-color: #262259 !important;
  background-color: #262259 !important;
}

.confirmFilterUsersAll {
  color: #fff;
  border-color: #262259;
  background-color: #262259;
}

.confirmFilterUsersAll:hover {
  color: #262259 !important;
  border-color: #262259 !important;
  background-color: #fff !important;
}

.confirmFilterUsersInactive {
  color: #fff;
  background-color: #bbbec0;
  border-color: #262259;
}

.confirmFilterUsersInactive:hover {
  color: #000 !important;
  background-color: #bbbec0 !important;
  border-color: #262259 !important;
}

/* MEMBERSHIP LEVELS BLOCKS */
.prospect-block {
  display: inline-block;
  padding: 8px 8px 8px;
  background: #bcbec0;
  width: 180px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.beamer-block {
  display: inline-block;
  padding: 8px 8px 8px;
  background: #f59e0b;
  width: 180px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pathSeeker-block {
  display: inline-block;
  padding: 8px 8px 8px;
  background: #13c296;
  width: 180px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.teacher-block {
  display: inline-block;
  padding: 8px 8px 8px;
  background: #01a9db;
  width: 180px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* CONFIRM MODALS */
.confirmModalStyle .ant-modal-content {
  width: 300px;
  background-color: #545a6d !important;
}

.ant-modal .ant-modal-confirm {
  width: 300px;
}

.ant-modal-confirm .ant-modal-confirm-content {
  color: #fff !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center !important;
}

.ant-modal-confirm .ant-modal-confirm-paragraph {
  text-align: center;
}
